import React from 'react';

function Footer() {
  return (
    <footer className='footer text-white pt-4 mt-auto' >
      <div className="container pt-1" style={{ textAlign: 'left' }}>
        <div className="row" style={{  marginLeft: '100px'}}>
          <div className="col-xl-3 col-md-6 col-sm-12">
            <p style={{ textDecoration: 'underline', marginBottom: '10px' }}>Hosting</p>
            <ul style={{ listStyleType: 'none', padding: 0, margin: 0 }}>
              <li style={{fontSize:"0.90rem" }}><a href="#" className="text-decoration-none link-secondary">Shared Hosting</a></li>
              <li style={{fontSize:"0.90rem" }}><a href="#" className="text-decoration-none link-secondary">VPS Hosting</a></li>
              <li style={{fontSize:"0.90rem" }}><a href="#" className="text-decoration-none link-secondary">WordPress Hosting</a></li>
            </ul>
          </div>
          <div className="col-xl-3 col-md-6 col-sm-12">
            <p style={{ textDecoration: 'underline', marginBottom: '10px' }}>Domains</p>
            <ul style={{ listStyleType: 'none', padding: 0, margin: 0 }}>
              <li style={{fontSize:"0.90rem" }}><a href="#" className="text-decoration-none link-secondary">Register Domains</a></li>
              <li style={{fontSize:"0.90rem" }}><a href="#" className="text-decoration-none link-secondary">Domain Pricing</a></li>
              <li style={{fontSize:"0.90rem" }}><a href="#" className="text-decoration-none link-secondary">Sell Your Domain</a></li>
            </ul>
          </div>
          <div className="col-xl-3 col-md-6 col-sm-12">
            <p style={{ textDecoration: 'underline', marginBottom: '10px' }}>Services</p>
            <ul style={{ listStyleType: 'none', padding: 0, margin: 0 }}>
              <li style={{fontSize:"0.90rem" }}><a href="#" className="text-decoration-none link-secondary">Social Media Marketing</a></li>
              <li style={{fontSize:"0.90rem" }}><a href="#" className="text-decoration-none link-secondary">Email Marketing</a></li>
              <li style={{fontSize:"0.90rem" }}><a href="#" className="text-decoration-none link-secondary">Search Engine Marketing</a></li>
              <li style={{fontSize:"0.90rem" }}><a href="#" className="text-decoration-none link-secondary">Billboard Marketing</a></li>
              <li style={{fontSize:"0.90rem" }}><a href="#" className="text-decoration-none link-secondary">SMS/WhatsApp Marketing</a></li>
            </ul>
          </div>
          <div className="col-xl-3 col-md-6 col-sm-12">
            <p style={{ textDecoration: 'underline', marginBottom: '10px' }}>Company</p>
            <ul style={{ listStyleType: 'none', padding: 0, margin: 0 }}>
              <li style={{fontSize:"0.90rem" }}><a href="#" className="text-decoration-none link-secondary">About</a></li>
              <li style={{fontSize:"0.90rem" }}><a href="#" className="text-decoration-none link-secondary">Portfolio</a></li>
              <li style={{fontSize:"0.90rem" }}><a href="#" className="text-decoration-none link-secondary">Privacy Policy</a></li>
              <li style={{fontSize:"0.90rem" }}><a href="#" className="text-decoration-none link-secondary">Terms Of Use</a></li>
              <li style={{fontSize:"0.90rem" }}><a href="#" className="text-decoration-none link-secondary">Brands</a></li>
              <li style={{fontSize:"0.90rem" }}><a href="#" className="text-decoration-none link-secondary">Contacts</a></li>
            </ul>
          </div>
        </div>
        <div className="row justify-content-center" style={{ marginTop: '20px' }}>
          &copy; 2024 - Hostin.pk. All Rights Reserved.
        </div>
      </div>
    </footer>
  );
}

export default Footer;
