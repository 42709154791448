import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SentimentDissatisfiedRounded, DoneSharp } from "@mui/icons-material";
import { SearchOutlined } from "@mui/icons-material";
import {
  FormControl,
  InputAdornment,
  OutlinedInput,
  Button,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { fetchDomainAvailability } from "../state/domainSlice";

function Home() {
  const [domain, setDomain] = useState("");
  const dispatch = useDispatch();
  const { data, loading, error } = useSelector((state) => state.domain);
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const navigate = useNavigate();

  const handleSearch = () => {
    dispatch(fetchDomainAvailability(domain));
  };

  const handlePayment = () => {
   navigate('/register')
        
    //   navigate("/login", { state: { from: { pathname: "/checkout", state: { domain } } } });
    // } else {
    //   navigate("/checkout", { state: { domain } });
    // }
  }

  return (
    <div style={{ textAlign: "center", padding: "20px" }}>
      <FormControl
        style={{ maxHeight: '7ch', maxWidth: '50ch' }}
        sx={{
          m: 1,
          width: { xs: "80%", sm: "90%", md: '70ch' },
          maxWidth: "70ch",
          border: "2px solid #D9D9D9",
          background: "var(--color-search-bar)",
          borderRadius:'10px'
        }}
        variant="outlined"
      >
        <OutlinedInput
          value={domain}
          onChange={(e) => setDomain(e.target.value)}
          endAdornment={
            <InputAdornment position="end">
              <SearchOutlined
                sx={{
                  color: "var(--color-icon)",
                  cursor: "pointer",
                  fontSize: "1.5rem",
                }}
                onClick={handleSearch}
              />
            </InputAdornment>
          }
          aria-describedby="outlined-weight-helper-text"
          inputProps={{
            "aria-label": "domain",
          }}
          sx={{
            height: "5rem",
            "& .MuiOutlinedInput-input": {
              padding: "14px",
              fontFamily: "'Trebuchet MS', sans-serif",
              fontStyle: "italic",
              color: "color",
              textAlign: "center",
              fontSize: "0.80rem",
            },
          }}
          placeholder="Write here the domain name you want to register.."
        />
      </FormControl>
      {loading && <Typography variant="body1">Loading...</Typography>}
      {error && (
        <Typography variant="body1" color="error">
          {error}
        </Typography>
      )}
      {data &&
        (data?.data?.available ? (
          <div>
            <Typography variant="body1"><b><DoneSharp /> Yeah, {data?.data?.domain} is available!</b></Typography>
            {data?.data?.available && (
              <Button
                variant="contained"
                color="primary"
                onClick={handlePayment}
                sx={{ mt: 2, width: '100%', background: 'var(--color-button)', color: 'var(--color-icon)' }}
              >
                Pay Now
              </Button>
            )}
          </div>
        ) :
          <div>
            <Typography variant="body1" color="error"> <b><SentimentDissatisfiedRounded /> Oops, {data?.data?.domain} is not available!</b></Typography>
          </div>
        )}
    </div>
  );
}

export default Home;
