// src/app/store.js
import { configureStore } from '@reduxjs/toolkit';
import authReducer from '../state/authSlice';
import domainReducer from '../state/domainSlice';
const store = configureStore({
  reducer: {
    auth: authReducer,
    domain: domainReducer,
  },
});

export default store;
