import React, { useEffect, useState } from 'react';

const PaymentConfirmation = () => {
  const [confirmationStatus, setConfirmationStatus] = useState(null);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const authToken = urlParams.get('auth_token');

    if (authToken) {
      // confirmPayment(authToken);
    }
  }, []);

  const confirmPayment = async (authToken) => {
    try {
      const response = await fetch('http://localhost:5000/api/v1/payment/payment-confirmation', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ authToken }),
      });

      const result = await response.json();

      if (result.success) {
        setConfirmationStatus('Payment successful!');
      } else {
        setConfirmationStatus('Payment failed: ' + result.message);
      }
    } catch (error) {
      setConfirmationStatus('Payment confirmation failed. Please try again.');
    }
  };

  return (
    <div>
      {confirmationStatus ? (
        <h2>{confirmationStatus}</h2>
      ) : (
        <h2>Confirming your payment, please wait...</h2>
      )}
    </div>
  );
};

export default PaymentConfirmation;
