import React from 'react';
import { Container, Navbar } from 'react-bootstrap';
import { FaMoon } from 'react-icons/fa';

function Header({ toggleDarkMode }) {
  return (
    <>
<nav class="navbar navbar-expand-md navbar-dark App-header">
    <div class="container-fluid">
        <div class="navbar-collapse collapse w-100 order-1 order-md-0 dual-collapse2">
            {/* <ul class="navbar-nav me-auto">
                <li class="nav-item active">
                    <a class="nav-link" href="#">Left</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" href="//codeply.com">Codeply</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" href="#">Link</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" href="#">Link</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" href="#">Link</a>
                </li>
            </ul> */}
        </div>
        <div class="mx-auto order-0">
           <img src="/logo.png" alt="logo" style={{ maxHeight:'70px'}}/>
        </div>
        <div class="navbar-collapse collapse w-100 order-3 dual-collapse2 justify-content-end">
          <img src="/icon1.png" alt="icon" className="me-2" style={{ maxHeight:'30px'}} />
          <img src="/icon2.png" alt="icon" className="me-2" style={{ maxHeight:'30px'}}/>
          <div className="theme-switcher" onClick={toggleDarkMode} style={{ cursor: 'pointer' }}>
            <FaMoon />
          </div>
            {/* <ul class="navbar-nav ms-auto">
                <li class="nav-item">
                    <a class="nav-link" href="#">Right</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" href="#">Link</a>
                </li>
            </ul> */}
        </div>
    </div>
</nav>



    
    </>
  );
}

export default Header;
