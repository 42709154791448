import React, { useState } from 'react';
import {
  Avatar,
  Button,
  TextField,
  Link,
  Typography,
  Grid,
  Box,
  CssBaseline,
  Paper,
} from '@mui/material';
import { LockOutlined } from '@mui/icons-material';
import { useNavigate, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { login } from '../state/authSlice';
import { useTheme } from '@mui/material/styles';

function Copyright() {
  return (
    <Typography variant="body2" align="center" sx={{ color: 'var(--color-text)' }}>
      {'Copyright © '}
      <Link color="inherit" href="https://material-ui.com/">
        Your Website
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

export default function Login() {
  const theme = useTheme();
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const { loading, error, isAuthenticated } = useSelector(state => state.auth);
  const [account, setAccount] = useState({ username: '', password: '' });

  const handleAccountChange = (property, event) => {
    setAccount({ ...account, [property]: event.target.value });
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const result = await dispatch(login({ email: account.username, password: account.password }));
      if (login.fulfilled.match(result)) {
        setAccount({ username: '', password: '' });
        console.log(location,"location=>>")
        const from = location.state?.from?.pathname || '/register';
        const domain = location.state?.from?.state?.domain; // Retrieve domain from login state
        console.log(domain,"domain=>")
        navigate(from, { replace: true, state: { domain } });
      }
    } catch (err) {
      console.error(err);
    }
  };
  

  return (
    <Grid container justifyContent="center" alignItems="center">
      <CssBaseline />
      <Grid
        item
        xs={12}
        sm={8}
        md={5}
        component={Paper}
        elevation={6}
        square
        sx={{
          background: 'var(--color-header)',
          padding: theme.spacing(4),
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Box component="form" onSubmit={handleLogin} sx={{ width: '300px', textAlign: 'center' }}>
          <Avatar
            sx={{ m: 1, bgcolor: 'var(--color-secondary)', marginLeft: 'auto', marginRight: 'auto' }}
          >
            <LockOutlined style={{ color: 'var(--color-icon)' }} />
          </Avatar>
          <Typography component="h1" variant="h5" sx={{ color: 'var(--color-icon)' }}>
            Sign in
          </Typography>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="username"
            label="Username"
            name="username"
            autoComplete="username"
            autoFocus
            value={account.username}
            onChange={e => handleAccountChange('username', e)}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            value={account.password}
            onChange={e => handleAccountChange('password', e)}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            disabled={loading}
            sx={{ mt: 3, mb: 2 }}
          >
            Sign In
          </Button>
          {error && (
            <Typography variant="body2" color="error">
              {error.message}
            </Typography>
          )}
          <Grid container>
            <Grid item>
              <Link href="/register" variant="body2" sx={{ color: 'var(--color-icon)' }}>
                {"Don't have an account? Sign Up"}
              </Link>
            </Grid>
          </Grid>
          <Box mt={5}>
            <Copyright />
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
}
