import React, { useState, useEffect } from "react";
import CryptoJS from 'crypto-js';
import {
  Avatar,
  Button,
  CssBaseline,
  TextField,
  Link,
  Paper,
  Box,
  Grid,
  Typography,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  CircularProgress,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@mui/material";
import { LockOutlined } from "@mui/icons-material";
import { useTheme } from "@mui/material/styles";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { register } from "../state/authSlice";
import { loadStripe } from "@stripe/stripe-js";
import { Buffer } from "buffer";
import qs from 'qs';

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <Link color="inherit" href="https://material-ui.com/">
        Your Website
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

export default function Register() {
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  console.log(isAuthenticated, "isAuthenticated");
  const location = useLocation();
  const { domain } = location.state || {};
  const stripePromise = loadStripe(
    "pk_test_51Mb4EyAHwl7qaxRBbV5iAClDfgwxB9BJIatT14AujEU2WTixFV25Y7vD7To9gAtr12nxDTKunDDej9Y4aQxtSvzI00a8oHEwRw"
  );
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [account, setAccount] = useState({
    name: "",
    email: "",
    password: "",
    phoneNumber: "",
    companyName: "",
    addressLine1: "",
    addressLine2: "",
    city: "",
    state: "",
    zipcode: "",
    country: "",
    paymentMethod: "",
  });
  const [error, setError] = useState("");
  const [countries, setCountries] = useState([]);
  const [loadingCountries, setLoadingCountries] = useState(true);
  const { loading } = useSelector((state) => state.auth);
  const [returnUrl, setReturnUrl] = useState(
    "https://fend.hostin.pk/payment-confirmation"
  );
  useEffect(() => {
    // Fetch countries when component mounts
    axios
      .get("https://restcountries.com/v3.1/all")
      .then((response) => {
        const countryList = response.data.map((country) => country.name.common);
        setCountries(countryList.sort()); // Sort countries alphabetically
        setLoadingCountries(false);
      })
      .catch((error) => {
        console.error("Error fetching countries:", error);
        setLoadingCountries(false);
      });
  }, []);
  const handleAPFPayment = async () => {
    console.log("call handleAPFPayment");
    try {
      const getData = await axios.post('https://cwscp.hostin.pk/api/v1/payment/easy-pay')
      console.log(getData,"getdata--->>>>>>")

      const generateTransactionReferenceNumber = () => {
        return Math.random().toString(36).substr(2, 9); // Generates a random alphanumeric string
      };
      
      const transactionReferenceNumber = generateTransactionReferenceNumber();
      console.log(transactionReferenceNumber, "transactionReferenceNumber");
      
      const postData = {
        HS_IsRedirectionRequest: 0,
        HS_ChannelId: "1001",
        HS_MerchantId: "16506",
        HS_StoreId: "022010",
        HS_ReturnURL: `https://fend.hostin.pk/payment`,
        HS_MerchantHash: "OUU362MB1urpXtnMpU0/if9W2C4ZuWwbCphTWcLREpfzRqIzVAeBAQ==",
        HS_MerchantUsername: "hajiru",
        HS_MerchantPassword: "KL5v6xUT23JvFzk4yqF7CA==",
        HS_TransactionReferenceNumber: transactionReferenceNumber,
      };
      
      // Encrypt the data
      const keyOne = "T8hRYxe8XrKbtjBn"; // 16-byte key
      const keyTwo = "3967957695762366"; // 16-byte IV
      
      const encryptData = (data, keyOne, keyTwo) => {
        const encrypted = CryptoJS.AES.encrypt(data, CryptoJS.enc.Utf8.parse(keyOne), {
          iv: CryptoJS.enc.Utf8.parse(keyTwo),
          mode: CryptoJS.mode.CBC,
          padding: CryptoJS.pad.Pkcs7,
        });
        return encrypted.toString();
      };
      
      const dataString = Object.entries(postData)
        .map(([key, value]) => `${key}=${value}`)
        .join("&");
      
      const requestHash = encryptData(dataString, keyOne, keyTwo);
      postData.HS_RequestHash = requestHash;
      
      console.log('Encrypted Request Data:', postData);
      
      // Call the Handshake API
      // const hsResponse = await axios.post('https://sandbox.bankalfalah.com/HS/HS/HS', qs.stringify(postData), {
      //   headers: {
      //     "Content-Type": 'application/x-www-form-urlencoded',
      //   },
      // });
      
      // console.log(hsResponse.data, "HS API Response");
      
      // // Check if the handshake was successful and get the AuthToken
      // if (hsResponse.data && hsResponse.data.success === "true") {
      //   const authToken = hsResponse.data.AuthToken;
      //   const returnURL = hsResponse.data.ReturnURL;
        
      //   // Prepare data for the SSO API
      //   const ssoData = {
      //     AuthToken: authToken,
      //     RequestHash: requestHash, // Same hash used in handshake
      //     ChannelId: "1001",
      //     Currency: "PKR",
      //     IsBIN: "0",
      //     ReturnURL: returnURL,
      //     MerchantId: "16506",
      //     StoreId: "022010",
      //     MerchantHash: "OUU362MB1urpXtnMpU0/if9W2C4ZuWwbCphTWcLREpfzRqIzVAeBAQ==",
      //     MerchantUsername: "hajiru",
      //     MerchantPassword: "KL5v6xUT23JvFzk4yqF7CA==",
      //     TransactionTypeId: "3", // Set your desired transaction type
      //     TransactionReferenceNumber: transactionReferenceNumber,
      //     TransactionAmount: "1000", // Set your desired transaction amount
      //   };
        
      //   console.log('SSO API Data:', ssoData);
        
      //   // Call the SSO API
      //   const ssoResponse = await axios.post('https://sandbox.bankalfalah.com/SSO/SSO/SSO', qs.stringify(ssoData), {
      //     headers: {
      //       "Content-Type": 'application/x-www-form-urlencoded',
      //     },
      //   });
        
      //   console.log(ssoResponse.data, "SSO API Response");
      // } else {
      //   console.error("Handshake Unsuccessful");
      // }
    } catch (error) {
      console.error("Error:", error);
    }
  };
  
  
  // const handleAPGPayment = async () => {
  //   try {
  //     // Clear previous errors
  //     setError(null);

  //     // Example data; replace with actual values or fetch from an API
  //     const formData = {
  //       HS_RequestHash: "generated_hash", // Generate this hash using your server
  //       HS_IsRedirectionRequest: 1,
  //       HS_ChannelId: "1001",
  //       HS_ReturnURL: "http://localhost:3000/payment-confirmation",
  //       // HS_MerchantId: 'OUU362MB1uq7Mb9IHu9SwZ6eOfyXrUtusllIFAm7M4Ys9xK6Eb0FucmuguYG7mblomP4ZJtF8dJ2vQuZPdzzkQ==',
  //       // HS_StoreId: paymentDetails.storeId,
  //       HS_MerchantHash: "OUU362MB1uq7Mb9IHu9SwZ6eOfyXrUtusllIFAm7M4Ys9xK6Eb0FucmuguYG7mblomP4ZJtF8dJ2vQuZPdzzkQ==", // Should be generated and secured on your server
  //       HS_MerchantUsername: "yxehiv", // Secure and retrieve from your server
  //       HS_MerchantPassword: "iPp6RjND5MhvFzk4yqF7CA==", // Secure and retrieve from your server
  //       // HS_TransactionReferenceNumber:
  //       //   paymentDetails.transactionReferenceNumber,
  //     };

  //     // Create a form and submit it to APG
  //     const form = document.createElement("form");
  //     form.method = "POST";
  //     form.action = "https://sandbox.bankalfalah.com/HS/HS/HS";

  //     for (const key in formData) {
  //       const input = document.createElement("input");
  //       input.type = "hidden";
  //       input.name = key;
  //       input.value = formData[key];
  //       form.appendChild(input);
  //     }

  //     document.body.appendChild(form);
  //     form.submit();
  //   } catch (error) {
  //     setError("An error occurred while processing your request.");
  //   }
  // };

  const handleAccountChange = (property, event) => {
    setAccount({ ...account, [property]: event.target.value });
  };
  const handleCardPayment = async () => {
    try {
      setError(null); // Clear previous errors
      const response = await axios.post(
        "https://cwscp.hostin.pk/api/v1/payment/create-checkout-payment",
        {
          domain,
          paymentMethod: "card",
        }
      );
      const sessionId = response.data.id;
      const stripe = await stripePromise;

      if (stripe) {
        const { error } = await stripe.redirectToCheckout({ sessionId });
        if (error) {
          console.error("Error redirecting to checkout:", error);
          setError("Failed to process payment. Please try again.");
        }
      } else {
        console.error("Stripe.js has not loaded yet.");
      }
    } catch (err) {
      console.error("Stripe load error:", err);
      setError(err.message);
    }
  };
  // const handleRegister = async (e) => {
  //   e.preventDefault();
  //   try {
  //     // Attempt to register the user
  //     const result = await dispatch(register(account));

  //     // Check if the user is already registered
  //     if ( result.payload && result.payload.message === 'User is already registered') {
  //       setError('User is already registered, please login.');
  //     } else if (register.fulfilled.match(result)) {
  //       // If the registration is successful and payment method is "Card" for non-Pakistan users
  //       if (account.paymentMethod === 'Card' && account.country !== 'Pakistan') {
  //         await handleCardPayment();
  //       } else {
  //         navigate('/login'); // Navigate to login after successful registration
  //       }
  //     } else {
  //       console.log(result,"resoooooo")
  //       // If registration failed due to other reasons
  //       setError(result.payload.message || 'Registration failed');
  //     }
  //   } catch (error) {
  //     // Handle any other errors that may occur
  //     setError('An error occurred');
  //   }
  // };
  const handleRegister = async (e) => {
    e.preventDefault();
    try {
      // Attempt to register the user by calling your register API
      const response = await dispatch(register(account));
      console.log(response, "response");
      if (response.payload.success) {
        if (isAuthenticated) {
          console.log("call isAuthenticated");
          if (
            account.paymentMethod === "Card" &&
            account.country !== "Pakistan"
          ) {
            await handleCardPayment();
          } else if (
            account.paymentMethod === "APF Card" &&
            account.country == "Pakistan"
          ) {
            console.log("call else if");
            await handleAPFPayment();
            // Navigate to login after successful registration
          }
        } else {
          if (response.payload.success) {
            if (
              account.paymentMethod === "Card" &&
              account.country !== "Pakistan"
            ) {
              await handleCardPayment();
            } else if (
              account.paymentMethod === "APF Card" &&
              account.country == "Pakistan"
            ) {
              console.log("call else if");
              await handleAPFPayment();
              // Navigate to login after successful registration
            }
          } else {
            setError(response.payload.message);
          }
        }
        // If the user is successfully registered and the payment method is "Card" for non-Pakistan users
      } else {
        console.log("call esslse---", account);
        if (
          isAuthenticated &&
          response.payload.message == "User Already Exists!"
        ) {
          if (
            account.paymentMethod === "Card" &&
            account.country !== "Pakistan"
          ) {
            await handleCardPayment();
          } else if (
            account.paymentMethod === "APF Card" &&
            account.country == "Pakistan"
          ) {
            console.log("call else if");
            await handleAPFPayment();
            // Navigate to login after successful registration
          }
        } else {
          if (response.payload.success) {
            if (
              account.paymentMethod === "Card" &&
              account.country !== "Pakistan"
            ) {
              await handleCardPayment();
            } else if (
              account.paymentMethod === "APF Card" &&
              account.country == "Pakistan"
            ) {
              console.log("call else if");
              await handleAPFPayment();
              // Navigate to login after successful registration
            }
          } else {
            setError(response.payload.message);
          }
        }
      }
    } catch (error) {
      console.log(error, "rrr");
      if (
        error.response &&
        error.response.status === 400 &&
        error.response.data.message === "User Already Exists!"
      ) {
        // If the user is already registered but not logged in
        setError("User is already registered, please login.");
      } else {
        // Handle any other errors that may occur
        setError(error.response?.data.message || "Registration failed");
      }
    }
  };

  const renderPaymentOptions = () => {
    const handlePaymentMethodChange = (event) => {
      handleAccountChange("paymentMethod", event);
    };

    const isManualBankAccountSelected =
      account.paymentMethod === "Manual Bank Account";

    return (
      <FormControl component="fieldset">
        <label htmlFor="paymentMethod">Payment Method</label>
        <RadioGroup
          name="paymentMethod"
          value={account.paymentMethod}
          onChange={handlePaymentMethodChange}
        >
          {account.country === "Pakistan" ? (
            <>
              <FormControlLabel
                value="APF Bank Account"
                control={<Radio />}
                label="Alfalah Bank Transfer"
              />
              <FormControlLabel
                value="APF Card"
                control={<Radio />}
                label="Alfalah Card"
              />
            </>
          ) : (
            <FormControlLabel value="Card" control={<Radio />} label="Card" />
          )}
          <FormControlLabel
            value="Manual Bank Account"
            control={<Radio />}
            label="Manual Bank Account"
          />
        </RadioGroup>

        {isManualBankAccountSelected && (
          <div>
            <TextField
              label="Account Holder Name"
              value={account.accountHolderName || ""}
              onChange={(e) =>
                handleAccountChange("accountHolderName", e.target.value)
              }
              fullWidth
              margin="normal"
            />
            <TextField
              label="Account Number"
              value={account.accountNumber || ""}
              onChange={(e) =>
                handleAccountChange("accountNumber", e.target.value)
              }
              fullWidth
              margin="normal"
            />
            <TextField
              label="IFSC Code"
              value={account.ifscCode || ""}
              onChange={(e) => handleAccountChange("ifscCode", e.target.value)}
              fullWidth
              margin="normal"
            />
          </div>
        )}
      </FormControl>
    );
  };

  return (
    <Grid container component="main" sx={{ justifyContent: "center" }}>
      <CssBaseline />
      <Grid
        item
        xs={12}
        sm={8}
        md={5}
        component={Paper}
        elevation={6}
        square
        sx={{
          background: "var(--color-header)",
          padding: theme.spacing(4),
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: theme.palette.secondary.main }}>
          <LockOutlined />
        </Avatar>
        <Typography component="h1" variant="h5">
          Sign up
        </Typography>
        <Box
          component="form"
          noValidate
          onSubmit={handleRegister}
          sx={{ mt: 1 }}
        >
          <Grid container>
            <Grid item>
              <Link
                href="/login"
                variant="body2"
                sx={{ color: "var(--color-icon)" }}
              >
                {"Already have an account? Sign in"}
              </Link>
            </Grid>
          </Grid>
          {loadingCountries ? (
            <CircularProgress />
          ) : (
            <FormControl fullWidth margin="normal">
              <InputLabel id="country-label">Country</InputLabel>
              <Select
                labelId="country-label"
                id="country"
                value={account.country}
                onChange={(event) => handleAccountChange("country", event)}
                required
              >
                {countries.map((country) => (
                  <MenuItem key={country} value={country}>
                    {country}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
          {[
            "name",
            "email",
            "phoneNumber",
            "companyName",
            "password",
            "addressLine1",
            "addressLine2",
            "city",
            "state",
            "zipcode",
          ].map((field) => (
            <TextField
              key={field}
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id={field}
              label={field.charAt(0).toUpperCase() + field.slice(1)}
              name={field}
              autoComplete={field}
              autoFocus={field === "name"}
              type={field === "password" ? "password" : "text"}
              onChange={(event) => handleAccountChange(field, event)}
            />
          ))}

          {renderPaymentOptions()}

          {error && <Typography color="error">{error}</Typography>}
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            sx={{
              mt: 3,
              mb: 2,
              background: "var(--color-button)",
              color: "var(--color-icon)",
            }}
            disabled={loading}
          >
            Continue
          </Button>
          <Box mt={5}>
            <Copyright />
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
}
