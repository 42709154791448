// src/components/ProtectedRoute.js
import React, { useEffect } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setAuthenticated } from '../state/authSlice';

const ProtectedRoute = () => {
  const location = useLocation()
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const dispatch = useDispatch();
  
  useEffect(() => {
    const token = localStorage.getItem('jwtToken'); // Or retrieve from cookies
    if (token) {
      // Optionally, validate token here
      dispatch(setAuthenticated(true));
    }
  }, [dispatch]);

  return isAuthenticated ? <Outlet /> : <Navigate to="/regsiter" state={{ from: location }} replace />;
};

export default ProtectedRoute;
