// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;700&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.App {
  text-align: center;
  position: relative;
  height: 100vh;
  font-family: 'Trebuchet MS', sans-serif;
}`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AACA;EACE,kBAAkB;EAClB,kBAAkB;EAClB,aAAa;EACb,uCAAuC;AACzC","sourcesContent":["@import url(\"https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;700&display=swap\");\n.App {\n  text-align: center;\n  position: relative;\n  height: 100vh;\n  font-family: 'Trebuchet MS', sans-serif;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
