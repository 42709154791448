import React from 'react';
import { Container } from 'react-bootstrap';

function MainContent({ children }) {
  return (
    <Container className="content container my-5">
      {children}
    </Container>
  );
}

export default MainContent;
