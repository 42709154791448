import React from "react";

function SharedHosting() {
  return (
    <div class="container">
      <div class="row">
        <div class="col-md-3 mb-3">
          <div class="card" style={{ backgroundColor:'#121212' , border:'2px solid #fff'}}>
            <div class="card-body p-2">
              <b class="card-title text-start"><p class="mb-0">Card 1</p></b>
              <p class="text-start small">Rs2,000/mo (billed annually)</p>
                <ul class="list-bullet">
              <li class="text-start small">1GB Blazing Fast SSD </li>
              <li class="text-start small">10 GB Monthely Bandwidth </li>
              <li class="text-start small">Unlimited FTP Accounts </li>
              <li class="text-start small">Unlimited Email Accounts </li>
              <li class="text-start small">Powered by cPanel </li>
            </ul>
            <button class="w-100">Order</button>
            </div>
          </div>
        </div>
        <div class="col-md-3 mb-3">
          <div class="card" style={{ backgroundColor:'#121212' , border:'2px solid #fff'}}>
            <div class="card-body p-2">
              <b class="card-title text-start"><p class="mb-0">Card 2</p></b>
              <p class="text-start">Rs2,000/mo (billed annually)</p>
                <ul class="list-bullet">
              <li class="text-start">3GB Blazing Fast SSD </li>
              <li class="text-start">10 GB Monthely Bandwidth </li>
              <li class="text-start">Unlimited FTP Accounts </li>
              <li class="text-start">Unlimited Email Accounts </li>
              <li class="text-start">Powered by cPanel </li>
            </ul>
            <button class="w-100">Order</button>
              
            </div>
          </div>
        </div>
        <div class="col-md-3 mb-3">
          <div class="card" style={{ backgroundColor:'#121212' , border:'2px solid #fff'}}>
            <div class="card-body p-2">
              <b class="card-title text-start"><p class="mb-0">Card 3</p></b>
              <p class="text-start">Rs2,000/mo (billed annually)</p>
                <ul class="list-bullet">
              <li class="text-start">5GB Blazing Fast SSD </li>
              <li class="text-start">10GB Monthly Bandwidth </li>
              <li class="text-start">Unlimited FTP Accounts </li>
              <li class="text-start">Unlimited Email Accounts </li>
              <li class="text-start">Powered by cPanel </li>
            </ul>
            <button class="w-100">Order</button>
            
            </div>
          </div>
        </div>
        <div class="col-md-3 mb-3">
          <div class="card" style={{ backgroundColor:'#121212' , border:'2px solid #fff'}}>
            <div class="card-body p-2">
              <b class="card-title text-start"><p class="mb-0">Card 4</p></b>
              <p class="text-start">Rs2,000/mo (billed annually)</p>
                <ul class="list-bullet">
              <li class="text-start">Unmetered Blazing Fast SSD</li>
              <li class="text-start">Unmetered Monthly
              Bandwidth</li>
              <li class="text-start">Unlimited FTP Accounts </li>
              <li class="text-start">Unlimited Email Accounts</li>
              <li class="text-start">Powered by cPanel </li>
            </ul>
            <button class="w-100">Order</button>
              
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SharedHosting;
