// src/state/domainSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

export const fetchDomainAvailability = createAsyncThunk(
    'domain/fetchDomainAvailability',
    async (domainName, { rejectWithValue }) => {
      try {
        const response = await axios.get(`http://localhost:5000/api/v1/domain/isdomain-available?domain=${domainName}`);
        return response.data;
      } catch (error) {
        return rejectWithValue(error.message);
      }
    }
  );
const domainSlice = createSlice({
    name: 'domain',
    initialState: {
      data: null,
      loading: false,
      error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
      builder
        .addCase(fetchDomainAvailability.pending, (state) => {
          state.loading = true;
          state.error = null;
        })
        .addCase(fetchDomainAvailability.fulfilled, (state, action) => {
          state.loading = false;
          state.data = action.payload;
        })
        .addCase(fetchDomainAvailability.rejected, (state, action) => {
          state.loading = false;
          state.error = action.payload;
        });
    },
  });
  
  export default domainSlice.reducer;